.card-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px $body-color solid;
}

.image-product {
  width: auto;
}

.title {
    font-size: 18px;
    line-height: 30px;
}

.prices {
  display: flex;
}

.oldprice {
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  margin-left: 10px;
}

.contactUs {
  display: flex;
  flex-direction: row;
}

.contacts {
  display: flex;
  flex-direction: column;
}

.contacts a {
  margin-right: 10px;

}