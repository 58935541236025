.product {
  display: flex;
  max-width: 100%;
  flex-direction: column;
}

.default-image {
  width: 100%;
}

.gallery-products ul {
  display: flex;
  list-style: none;
}